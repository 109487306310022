<template>
  <div>
    <div class="e-breadcrumb">
      <span style="margin-left: -10px" @click="closeEvent">员工管理</span>
      > 新增
    </div>
    <div class="order-table-wrapper">
      <div class="e-card">
        <el-row>
          <el-col :span="12">
            <div>
              <el-form ref="form" label-width="230px" style="margin-top: 30px">
                <el-form-item size="small" label="员工编号:" required>
                  <el-input
                    v-model="employeeNo"
                    placeholder="请输入员工编号"
                  ></el-input>
                </el-form-item>
                <el-form-item size="small" label="员工姓名:" required>
                  <el-input
                    v-model="fullName"
                    placeholder="请输入员工姓名"
                  ></el-input>
                </el-form-item>

                <el-form-item size="small" label="员工昵称:" required>
                  <el-input
                    v-model="nickName"
                    placeholder="请输入员工昵称"
                  ></el-input>
                </el-form-item>

                <el-form-item size="small" label="性别:" required>
                  <el-radio-group v-model="sex">
                    <el-radio :label="0">男</el-radio>
                    <el-radio :label="1">女</el-radio>
                  </el-radio-group>
                </el-form-item>

                <el-form-item size="small" label="银行卡号:" required>
                  <el-input
                    v-model="bankNumber"
                    placeholder="请输入银行卡号"
                  ></el-input>
                </el-form-item>

                <el-form-item size="small" label="基本工资:" required>
                  <el-input
                    v-model="basePay"
                    placeholder="请输入基本工资"
                  ></el-input>
                </el-form-item>

                <el-form-item size="small" label="员工级别:" required>
                  <el-select
                    style="width: 100%"
                    v-model="gradeId"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in userGradeLis"
                      :key="item.id"
                      :label="item.gradeName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item size="small" label="是否可预约:" required>
                  <el-radio-group v-model="isAppointment">
                    <el-radio :label="0">不可预约</el-radio>
                    <el-radio :label="1">可预约</el-radio>
                  </el-radio-group>
                </el-form-item>
                <el-form-item size="small" label="入店日期:" required>
                  <el-date-picker
                    v-model="joinDate"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                  >
                  </el-date-picker>
                </el-form-item>

                <el-form-item size="small" label="员工班次:" required>
                  <el-select
                    style="width: 100%"
                    v-model="shiftId"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in shiftList"
                      :key="item.id"
                      :label="item.csName"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>

                <el-form-item size="small" label="考勤卡号:" required>
                  <el-input
                    v-model="timeCardNumber"
                    placeholder="请输入考勤卡号"
                  ></el-input>
                </el-form-item>

                <el-form-item size="small" label="身份证:" required>
                  <el-input
                    v-model="idCard"
                    placeholder="请输入身份证"
                  ></el-input>
                </el-form-item>

                <el-form-item size="small" label="手机:" required>
                  <el-input v-model="phone" placeholder="请输入手机"></el-input>
                </el-form-item>

                <el-form-item size="small" label="生日:" required>
                  <el-date-picker
                    v-model="birthday"
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择日期"
                  >
                  </el-date-picker>
                </el-form-item>

                <el-form-item size="small" label="员工状态:" required>
                  <el-radio-group v-model="employeeStatus">
                    <el-radio :label="0">在职</el-radio>
                    <el-radio :label="1">离职</el-radio>
                  </el-radio-group>
                </el-form-item>

                <el-form-item size="small" label="备注:" required>
                  <el-input
                    v-model="remarks"
                    placeholder="请输入备注"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
          </el-col>
          <el-col :span="12">
            <div style="margin-top: 30px; margin-left: 20%">
              <div style="width: 100%">
                <div style="width: 100%; margin-left: 20px">
                  <el-upload
                    class="e-file-upload"
                    :action="Base_url + '/admin/uploadImg'"
                    :on-success="imageSuccess"
                    :before-upload="beforeUpload"
                    :show-file-list="false"
                  >
                    <el-image
                      v-if="headPortrait"
                      style="width: 137px; height: 137px"
                      :src="headPortrait"
                      fit="contain"
                    ></el-image>
                    <div class="e-upload--text" v-if="!headPortrait">
                      <span
                        class="e-upload--icon el-icon-plus"
                        style="margin-top: 30px"
                      ></span>
                      <p>上传头像</p>
                    </div>
                    <p
                      class="e-file-upload-delete"
                      v-if="headPortrait"
                      @click.stop="headPortrait = ''"
                    >
                      <i class="el-icon-delete"></i>
                    </p>
                  </el-upload>
                </div>
                <div
                  style="
                    width: 100%;
                    float: left;
                    margin-top: 15px;
                    margin-bottom: 15px;
                    font-size: 20px;
                  "
                >
                  <p>设置小程序登录账户</p>
                </div>
                <div style="width: 100%; float: left">
                  <el-form ref="ss" label-width="88px">
                    <el-form-item size="small" label="账号:">
                      <el-input
                        v-model="loginName"
                        autocomplete="off"
                        maxlength="11"
                        show-word-limit
                        style="width: 30%"
                        placeholder="账号"
                      ></el-input>
                    </el-form-item>
                    <el-form-item size="small" label="密码:">
                      <el-input
                        style="position: fixed; bottom: -9999px"
                        type="password"
                      ></el-input>
                      <el-input
                        type="password"
                        v-model="loginPwd"
                        autocomplete="off"
                        show-word-limit
                        style="width: 30%"
                        placeholder="密码"
                      ></el-input>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
        <div style="margin-bottom: 30px; margin-top: 30px; text-align: center">
          <el-button size="small" @click="closeEvent">取 消</el-button>
          <el-button size="small" type="primary" @click="saveBbiEmployee"
            >确 定</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Base_url } from "../../config/index";

export default {
  components: {},

  data() {
    return {
      Base_url: "",
      id: "",
      employeeNo: "",
      fullName: "",
      nickName: "",
      sex: 0,
      bankNumber: "",
      basePay: "",
      gradeId: "",
      isAppointment: 0,
      joinDate: "",
      shiftId: "",
      timeCardNumber: "",
      idCard: "",
      phone: "",
      birthday: "",
      employeeStatus: 0,
      remarks: "",
      storeId: "",
      headPortrait: "",
      loginName: "",
      loginPwd: "",
      optionList: [],
      userGradeLis: [],
      shiftList: [],
    };
  },

  created() {
    this.Base_url = Base_url;
    this.getUserGradeList();
    this.getShiftList();
  },

  methods: {
    //图片相关
    imageSuccess(res) {
      this.headPortrait = res.fileUrl;
    },
    // 上传文件之前
    beforeUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 3;

      if (!isJPG && !isPNG) {
        this.$message.error("请上传JPG或PNG格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传大小不能超过 3MB!");
      }
      return (isJPG || isPNG) && isLt2M;
    },

    async getUserGradeList() {
      let res = await this.$get("/admin/getUserGradeList");
      if (res.code == 200) {
        this.userGradeLis = res.list;
      }
    },
    async getShiftList() {
      let res = await this.$get("/admin/getShiftList");
      if (res.code == 200) {
        this.shiftList = res.list;
      }
    },

    closeEvent() {
      this.$router.push("/employees/employee");
    },
    async saveBbiEmployee() {
      if (!this.employeeNo) {
        this.$message.error("请输入员工编号");
        return;
      }
      if (!this.fullName) {
        this.$message.error("请输入员工姓名");
        return;
      }
      if (!this.nickName) {
        this.$message.error("请输入员工昵称");
        return;
      }
      if (this.sex == null) {
        this.$message.error("请选择性别");
        return;
      }
      if (!this.bankNumber) {
        this.$message.error("请输入银行卡号");
        return;
      }
      if (!this.basePay) {
        this.$message.error("请输入基本工资");
        return;
      }
      if (!this.gradeId) {
        this.$message.error("请选择员工级别");
        return;
      }
      if (this.isAppointment == null) {
        this.$message.error("请选择是否可预约");
        return;
      }
      if (!this.joinDate) {
        this.$message.error("请选择入店日期");
        return;
      }
      if (this.shiftId == null) {
        this.$message.error("请选择员工班次");
        return;
      }
      if (!this.timeCardNumber) {
        this.$message.error("请输入考勤卡号");
        return;
      }
      if (!this.idCard) {
        this.$message.error("请输入身份证");
        return;
      }
      if (!this.phone) {
        this.$message.error("请输入手机");
        return;
      }
      if (!this.birthday) {
        this.$message.error("请选择生日");
        return;
      }
      if (this.employeeStatus == null) {
        this.$message.error("请选择员工状态");
        return;
      }
      if (!this.remarks) {
        this.$message.error("请输入备注");
        return;
      }

      let data = {
        employeeNo: this.employeeNo,
        fullName: this.fullName,
        nickName: this.nickName,
        sex: this.sex,
        bankNumber: this.bankNumber,
        basePay: this.basePay,
        gradeId: this.gradeId,
        isAppointment: this.isAppointment,
        joinDateStr: this.joinDate,
        shiftId: this.shiftId,
        timeCardNumber: this.timeCardNumber,
        idCard: this.idCard,
        phone: this.phone,
        birthdayStr: this.birthday,
        employeeStatus: this.employeeStatus,
        remarks: this.remarks,
        storeId: this.storeId,
        loginName: this.loginName,
        loginPwd: this.loginPwd,
        headPortrait: this.headPortrait,
      };
      let res = await this.$post("/admin/save_bbiEmployee", data);
      if (res.code == 200) {
        this.$message({
          showClose: true,
          message: res.message,
          type: "success",
        });
        this.$router.push("/employees/employee");
      } else {
        this.$message({
          showClose: true,
          message: res.message,
          type: "error",
        });
      }
    },
  },
};
</script>
